var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formObserver"},[_c('form',{ref:"fullVacancyForm",staticClass:"form"},[_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form__input",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("name"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form__input",attrs:{"type":"text","placeholder":"","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("email"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "email" ? _vm.$t("emailEnteredIncorrectly") : errors[0]))])])]}}])})],1),(_vm.tenantTypeUnselected)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("notSelected"))+" ")]):_vm._e(),_c('app-select',{attrs:{"placeholder":_vm.$t('whichShop'),"dropdown":_vm.tenants,"field":"address","attributes":true},model:{value:(_vm.tenantType),callback:function ($$v) {_vm.tenantType=$$v},expression:"tenantType"}}),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form__input",attrs:{"type":"text","placeholder":"","name":"mall"},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value}}}),_c('label',{attrs:{"for":"mall"}},[_vm._v(_vm._s(_vm.$t("companyName"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('div',{staticClass:"form__input-wrapper input-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t("rentDuration")))]),_c('div',{staticClass:"form__input-btns"},[_c('div',{staticClass:"form__input-btn"},[_vm._v("11 месяцев")])])]),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productsType),expression:"productsType"}],staticClass:"form__input",attrs:{"type":"text","placeholder":"","name":"goods"},domProps:{"value":(_vm.productsType)},on:{"input":function($event){if($event.target.composing){ return; }_vm.productsType=$event.target.value}}}),_c('label',{attrs:{"for":"goods"}},[_vm._v(_vm._s(_vm.$t("itemsType"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.otherTradePoints),expression:"otherTradePoints"}],staticClass:"form__input",attrs:{"type":"text","placeholder":"","name":"another-mall"},domProps:{"value":(_vm.otherTradePoints)},on:{"input":function($event){if($event.target.composing){ return; }_vm.otherTradePoints=$event.target.value}}}),_c('label',{staticClass:"long-label",attrs:{"for":"another-mall"}},[_vm._v(_vm._s(_vm.$t("otherShops"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.stuff),expression:"stuff"}],staticClass:"form__input",attrs:{"type":"number","placeholder":"","name":"personal"},domProps:{"value":(_vm.stuff)},on:{"input":function($event){if($event.target.composing){ return; }_vm.stuff=$event.target.value}}}),_c('label',{attrs:{"for":"personal"}},[_vm._v(_vm._s(_vm.$t("personalAmmount"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requiredSize),expression:"requiredSize"}],staticClass:"form__input",attrs:{"type":"number","placeholder":"","name":"size"},domProps:{"value":(_vm.requiredSize)},on:{"input":function($event){if($event.target.composing){ return; }_vm.requiredSize=$event.target.value}}}),_c('label',{attrs:{"for":"size"}},[_vm._v(_vm._s(_vm.$t("requiredPlace"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('div',{staticClass:"form__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"form__input",attrs:{"type":"text","placeholder":"","name":"phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('label',{attrs:{"for":"phone"}},[_vm._v(_vm._s(_vm.$t("contactPhone"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("fillForm") : ""))])])]}}])})],1),_c('button',{staticClass:"btn-form",on:{"click":function($event){$event.preventDefault();return _vm.sendForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("submit"))+" ")])],1),(_vm.isShowModalReceived)?_c('app-modal-received',{attrs:{"title":"formAccepted","text":"formAcceptedText","image":"parachute.svg","btn":"formAcceptedNice"},on:{"close":_vm.closeModalReceived}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }