<template>
  <validation-observer ref="formObserver">
    <form ref="fullVacancyForm" class="form">
      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input v-model="name" type="text" class="form__input" name="name" />
          <label for="name"
            >{{ $t("name") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>
      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="email">
          <input
            v-model="email"
            type="text"
            placeholder=""
            class="form__input"
            name="email"
          />
          <label for="email"
            >{{ $t("email") }}
            <span style="color: red">{{
              errors[0] === "email" ? $t("emailEnteredIncorrectly") : errors[0]
            }}</span></label
          >
        </validation-provider>
      </div>

      <p v-if="tenantTypeUnselected" style="color: red">
        {{ $t("notSelected") }}
      </p>
      <app-select
        v-model="tenantType"
        :placeholder="$t('whichShop')"
        :dropdown="tenants"
        field="address"
        :attributes="true"
      />

      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input
            v-model="company"
            type="text"
            placeholder=""
            class="form__input"
            name="mall"
          />
          <label for="mall"
            >{{ $t("companyName") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>

      <div class="form__input-wrapper input-wrapper">
        <span>{{ $t("rentDuration") }}</span>
        <div class="form__input-btns">
          <div class="form__input-btn">11 месяцев</div>
        </div>
      </div>

      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input
            v-model="productsType"
            type="text"
            placeholder=""
            class="form__input"
            name="goods"
          />
          <label for="goods"
            >{{ $t("itemsType") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>

      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input
            v-model="otherTradePoints"
            type="text"
            placeholder=""
            class="form__input"
            name="another-mall"
          />
          <label for="another-mall" class="long-label"
            >{{ $t("otherShops") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>

      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input
            v-model="stuff"
            type="number"
            placeholder=""
            class="form__input"
            name="personal"
          />
          <label for="personal"
            >{{ $t("personalAmmount") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>

      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input
            v-model="requiredSize"
            type="number"
            placeholder=""
            class="form__input"
            name="size"
          />
          <label for="size"
            >{{ $t("requiredPlace") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>

      <div class="form__group">
        <validation-provider v-slot="{ errors }" rules="required">
          <input
            v-model="phone"
            type="text"
            placeholder=""
            class="form__input"
            name="phone"
          />
          <label for="phone"
            >{{ $t("contactPhone") }}
            <span style="color: red">{{
              errors[0] === "required" ? $t("fillForm") : ""
            }}</span></label
          >
        </validation-provider>
      </div>

      <button class="btn-form" @click.prevent="sendForm">
        {{ $t("submit") }}
      </button>
    </form>
    <app-modal-received
      v-if="isShowModalReceived"
      @close="closeModalReceived"
      title="formAccepted"
      text="formAcceptedText"
      image="parachute.svg"
      btn="formAcceptedNice"
    />
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { sendTenantQuestionnaire } from "@/api/tenant-questionnaire";
import { getTenants } from "@/api/tenants";
export default {
  name: "TenantForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    AppSelect: () => import("@/components/common/AppSelect"),
    AppModalReceived: () =>
      import("@/components/pages/modal/AppModalReceived.vue"),
  },
  data() {
    return {
      // select
      tenants: [],

      // form variables
      tenantType: null,
      name: "",
      email: "",
      company: "",
      productsType: "",
      otherTradePoints: "",
      stuff: "",
      requiredSize: "",
      phone: "",

      questionnaireForm: null,

      tenantTypeUnselected: false,
      isShowModalReceived: false,
    };
  },
  created() {
    getTenants().then((res) => {
      this.tenants = res;
    });
  },
  watch: {
    tenantType: {
      handler() {
        this.tenantTypeUnselected = false;
      },
    },
  },
  methods: {
    sendForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (!this.tenantType) {
          this.tenantTypeUnselected = true;
        }
        if (success && !this.tenantTypeUnselected) {
          this.questionnaireForm = {
            data: {
              name: this.name,
              email: this.email,
              company: this.company,
              products_type: this.productsType,
              other_trade_points: this.otherTradePoints,
              stuff: this.stuff,
              required_size: this.requiredSize,
              phone: this.phone,
              rent_place: this.tenantType.id,
              createdAt: "2022-06-07T13:39:19.741Z",
              updatedAt: "2022-06-07T13:39:19.741Z",
              publishedAt: "2022-06-07T13:39:19.741Z",
              createdBy: "string or id",
              updatedBy: "string or id",
            },
          };
          sendTenantQuestionnaire(this.questionnaireForm).then((res) => {
            if (res.status === 200) {
              this.isShowModalReceived = true;
            }
          });
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    },
    closeModalReceived() {
      this.isShowModalReceived = false;
      location.reload();
    },
  },
};
</script>
